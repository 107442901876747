import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/MinervaTools.net/MinervaTools.net/src/layout/simpleMdx.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Terms of Service ("Terms")`}</h1>
    <Seo title={"Terms of Service"} mdxType="Seo" />
    <p>{`Last updated: 13.09.2021`}</p>
    <p>{`Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the `}<a parentName="p" {...{
        "href": "https://minervatools.net"
      }}>{`https://minervatools.net`}</a>{` website`}{
        /* and the My Mobile App (change this) mobile application*/
      }{` (the "Service") operated by MinervaTools ("us", "we", or "our").`}</p>
    <p>{`Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.`}</p>
    <p>{`By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.`}</p>
    <h2>{`Termination`}</h2>
    <p>{`We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.`}</p>
    <p>{`All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.`}</p>
    {
      /*## Subscriptions
      Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring basis. <!-- TODO: More text */
    }
    <h2>{`Software & Licensing`}</h2>
    <p>{`We sell licenses to our software and plugins. This license only does not grant any exclusive rights to the software or the source code behind it.
We may revoke licenses and usage rights at our own discretion for any reason, including if you breach the Terms or License Agreement.`}</p>
    <p>{`The sharing of licenses across multiple installations is not permitted and will result in immediate termination of the license and revocation of any usage rights granted.
This applies not only to the license in violation, but also any other licenses held by the individual or organization in violation.`}</p>
    <p>{`We do not allow reverse engineering our software with the goal of copying features or circumventing piracy measures. Any such attempt will immediately void all licenses and usage rights held by the
individual or organization in violation.`}</p>
    <p>{`We do our best to provide you with a bug-free software, but sometimes bugs not found in testing can get through to production.
We recommend for you to back up your data regularly. MinervaTools does not provide any warranty nor do we take any responsibility for data lost.`}</p>
    <h2>{`API usage`}</h2>
    <p>{`We provide an so called Application Programming Interface (API) to interact with MinervaTools software and services.
The use of this API must always before use on a production system, as incorrect use of the API may lead to data loss.
MinervaTools does not take any responsibility for any problems occuring in the use of the API.`}</p>
    <p>{`If you build a tool, product or service based on the API, you must make it clear you are not affiliated with MinervaTools and you may not imply your tool, product or service is endorsed by us.`}</p>
    <h2>{`Links To Other Web Sites`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "/legal/imprint"
      }}>{`our imprint`}</a>{` for more information on external links.`}</p>
    <h2>{`Changes`}</h2>
    <p>{`We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.`}</p>
    <h2>{`Contact Us`}</h2>
    <p>{`If you have any questions about these Terms, please contact us.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "/legal/imprint"
      }}>{`our imprint`}</a>{` for contact information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      